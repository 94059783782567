<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
      >
        <div class="subtitle-2 text--secondary">Template String</div>
      </v-col>
      <v-col
        class="mt-n6"
        cols="12"
      >
        <msnl-editor :min-line="5" :max-line="10" :value="mustache.M.stringTemplate || {M: '{}'}" @input="mustache.M.stringTemplate = $event"></msnl-editor>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import endpointTemplateForm from '@/template/endpointTemplate.json'

export default {
  name: 'ProcessorMustacheForm',
  model: {
    prop: 'mustache'
  },
  components: {
    'msnl-editor': () => import('@/components/MSNLEditor.vue')
  },
  props: {
    mustache: {
      type: Object,
      default () {
        return {
        }
      },
      required: true
    }
  },
  data: () => ({
  }),
  computed: {
    endpointEnum: function () {
      return endpointTemplateForm.enum.httpRequest
    },
    fieldRequire: function () {
      return v => !!v || 'field is required'
    },
    counterRequire: function (lessLength, moreLength) {
      if (lessLength && moreLength) {
        return v => ((v || '').length >= lessLength && (v || '').length <= moreLength) || `field must be ${lessLength}-${moreLength} characters`
      } else {
        return v => (v || '').length <= lessLength || `field must be ${lessLength} characters`
      }
    }
  },
  methods: {
  }
}
</script>
